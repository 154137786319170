import React, { useState } from "react";
import { CheckboxProps, Form, Image } from "semantic-ui-react";
import { sendEmail } from "../../sendEmail";
import map from "../../images/map_1.png";

export function Contactos() {
  const [values, setValues] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");

  function resetFields() {
    setName("");
    setEmail("");
    setPhone("");
    setText("");
    setValues([]);
  }

  function handleRadios(data: CheckboxProps) {
    const val: string = data.value?.toString() || "";
    const idx = values.findIndex((v) => v === val);
    console.log(val);
    if (idx === -1) setValues([...values, val]);
    else setValues([...values.slice(0, idx), ...values.slice(idx + 1)]);
  }

  return (
    <div>
      <div className="flex flex-row my-px-20">
        <a
          className="h-px-104 self-center"
          target="_blank"
          rel="noopener noreferrer"
          href="https://goo.gl/maps/4gdv8u1Q4RagN1JY8"
        >
          <Image className="w-px-104 h-px-104" src={map} />
        </a>
        <div className="text-left ml-px-20 ">
          <div className="flex flex-col mb-px-20">
            <div className="text-px-20 mb-px-5">A nossa morada:</div>
            <div className="text-px-13">
              Rua da Liberdade, 67 <br />
              Adão Lobo <br />
              2550-101 Cadaval
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-px-20 mb-px-5">Contactos:</div>
            <div className="text-px-13">
              <a href="tel:+351 262 691 407">+351 262 691 407</a> <br />
              <a href="tel:+351 919 982 886">+351 919 982 886</a> <br />
              <a href="mailto:vendas@facomac.pt">vendas@facomac.pt</a>
            </div>
          </div>
        </div>
      </div>
      <div className="text-px-30 font-semibold mb-px-20">Contacte-nos</div>
      <Form>
        <Form.Input
          fluid
          label="Nome:"
          placeholder="Insira o seu nome"
          value={name}
          onChange={(_, d) => setName(d.value)}
        />
        <Form.Input
          fluid
          label="Email:"
          placeholder="Insira o seu email"
          type="email"
          value={email}
          onChange={(_, d) => setEmail(d.value)}
        />
        <Form.Input
          fluid
          label="Telefone:"
          type="tel"
          placeholder="Insira o seu número de telefone"
          value={phone}
          onChange={(_, d) => setPhone(d.value)}
        />
        <Form.TextArea
          label="Texto:"
          placeholder="Em que podemos ser úteis?"
          value={text}
          onChange={(_, d) => setText(d.value?.toString() || "")}
        />
        <Form.Group>
          <Form.Radio
            label="Piscinas"
            value="piscinas"
            checked={values.find((t) => t === "piscinas") !== undefined}
            onClick={(_, d) => handleRadios(d)}
          />
          <Form.Radio
            label="Coberturas"
            value="coberturas"
            checked={values.find((t) => t === "coberturas") !== undefined}
            onClick={(_, d) => handleRadios(d)}
          />
          <Form.Radio
            label="Revestimentos"
            value="revestimentos"
            checked={values.find((t) => t === "revestimentos") !== undefined}
            onClick={(_, d) => handleRadios(d)}
          />
        </Form.Group>
        {!sent && (
          <button
            className="g-recaptcha rounded-3xl bg-hex-003C95 text-px-19  w-full font-bold text-white py-px-12 px-px-44 cursor-pointer text-center mt-px-20"
            disabled={loading}
            data-sitekey="6Ld6c3odAAAAANfDXTS-DVTdlRkBKKmQdXKSyn0M"
            data-callback="onSubmit"
            data-action="submit"
            onClick={async () => {
              if (!name || !email || !phone || !text) return;
              console.log("abc");
              setLoading(true);
              await sendEmail({
                nome: name,
                email,
                telefone: phone,
                texto: text,
                flags: values.join(","),
              });
              resetFields();
              setLoading(false);
              setSent(true);
            }}
          >
            Enviar
          </button>
        )}
        {sent && (
          <button
            className="rounded-3xl bg-hex-3CB371 text-px-19 w-full font-bold text-white py-px-12 px-px-44 cursor-pointer text-center mt-px-20"
            disabled={sent}
          >
            Obrigado! Iremos entrar em contacto consigo brevemente!{" "}
          </button>
        )}
      </Form>
    </div>
  );
}
