import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { Image } from "semantic-ui-react";

SwiperCore.use([Pagination, Autoplay, Navigation]);

export function TitleDescriptionAndCarousel({
  title,
  body,
  bodyHeightClassName = "h-px-130",
  images,
}: {
  title: string;
  body: string;
  bodyHeightClassName?: string;
  images: string[];
}) {
  return (
    <div className="flex flex-col">
      <div className="text-px-30 mb-px-10 text-center text-hex-003C95">
        {title}
      </div>
      <div className={`${bodyHeightClassName} mobile:hidden`}>{body}</div>
      <div
        className={`hidden mobile:inline mobile:mb-px-10 mobile:text-center`}
      >
        {body}
      </div>
      <div className="mobile:h-percent-30">
        <BodyCarousel images={images} />
      </div>
    </div>
  );
}

export function BodyCarousel({ images }: { images: string[] }) {
  return (
    <Swiper
      direction="horizontal"
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      centeredSlides
      spaceBetween={30}
      loop={images.length > 1}
      pagination={{ clickable: true }}
      navigation={images.length > 1}
      className="h-px-427 w-full"
    >
      {images.map((image) => (
        <SwiperSlide>
          <Image src={image} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
