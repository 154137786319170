import { createBrowserHistory } from "history";
import { Icon } from "semantic-ui-react";

export function Footer() {
  return (
    <div>
      <div className="w-full mobile:hidden bg-hex-3A3A3A text-white py-px-15 text-center relative bottom-0 left-0 right-0 z-0">
        Copyright Facomac © 2021 | Rua da Liberdade - 67, 2550-101, Adão Lobo -
        Cadaval | <a href="tel:+351 262 691 407">+351 262 691 407</a> -{" "}
        <a href="tel:+351 919 982 886">+351 919 982 886</a> |{" "}
        <a href="mailto:vendas@facomac.pt">vendas@facomac.pt</a>
      </div>
      <div
        className="hidden mobile:block mobile:fixed z-50 mobile:bottom-0 mobile:w-full mobile:bg-hex-003C95 py-px-20 text-center text-white mt-px-50 h-px-58 cursor-pointer"
        onClick={() => {
          createBrowserHistory().push("/contacto");
          window.location.reload();
        }}
      >
        <div className="text-px-16 flex flex-row justify-center">
          <Icon name="phone" className="" />
          <div className="-ml-px-15">Contacte-nos hoje!</div>
        </div>
      </div>
    </div>
  );
}
