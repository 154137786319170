import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { Form, Image } from "semantic-ui-react";

import trophies from "../../images/trophies.svg";
import foto1 from "../../images/homepage/foto1.jpg";
import foto2 from "../../images/homepage/foto2.jpg";
import foto3 from "../../images/homepage/foto3.jpg";
import foto4 from "../../images/homepage/foto4.jpg";
import foto5 from "../../images/homepage/foto5.jpg";

import bloc from "../../images/homepage/videos/bloc.mp4";
import blocCover from "../../images/homepage/videos/capa_video_bloc.png";
import lader from "../../images/homepage/videos/lader.mp4";
import leaderCover from "../../images/homepage/videos/capa_video_leader.png";
import { sendEmail } from "../../sendEmail";

SwiperCore.use([Pagination, Autoplay]);

export function Homepage() {
  return (
    <div>
      <div className="text-px-18 mb-px-60">
        A Facomac II é uma empresa de construção civil, especializada na
        construção e manutenção de piscinas, tanto privadas como públicas. Está
        no mercado desde 1989, assumindo-se como líder em Portugal no setor. Com
        mais de 5000 piscinas em todo o território nacional e além-fronteiras.
        Há 30 anos no mercado, a Facomac II tem sempre como aspirações responder
        aos desafios diários do mercado, adaptando-se e continuando sempre na
        vanguarda no setor da construção de piscinas.
      </div>

      <div className="relative z-0">
        <Swiper
          direction="horizontal"
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          className="h-px-427 w-px-650 z-0 mr-0 mobile:w-full mobile:h-percent-30"
        >
          <SwiperSlide>
            <Image src={foto1} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={foto2} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={foto3} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={foto4} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={foto5} />
          </SwiperSlide>
        </Swiper>
        <HomePageForm></HomePageForm>
        <div className="flex justify-end mb-px-20">
          <Image className="w-px-600" src={trophies} />
        </div>
        <div className="flex flex-row mobile:flex-col justify-between">
          <video
            controls
            src={bloc}
            poster={blocCover}
            className="w-percent-45 mobile:w-full mobile:mb-px-20"
          >
            <p>Your browser cannot play the provided video file.</p>
          </video>
          <video
            controls
            src={lader}
            poster={leaderCover}
            className="w-percent-45 mobile:w-full"
          >
            <p>Your browser cannot play the provided video file.</p>
          </video>
        </div>
      </div>
    </div>
  );
}

export function HomePageForm() {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");

  function resetFields() {
    setName("");
    setEmail("");
    setPhone("");
    setText("");
  }
  return (
    <div className="w-px-430 p-px-30 rounded-lg bg-form-blue absolute -top-px-30 z-10 mobile:hidden">
      <div className="text-px-30 font-semibold mb-px-20">Fale Connosco</div>
      <Form>
        <Form.Input
          fluid
          label="Nome:"
          placeholder="Insira o seu nome."
          value={name}
          onChange={(_, d) => setName(d.value)}
        />
        <Form.Input
          fluid
          label="Email:"
          placeholder="Insira o seu email."
          type="email"
          value={email}
          onChange={(_, d) => setEmail(d.value)}
        />
        <Form.Input
          fluid
          label="Telefone:"
          type="tel"
          value={phone}
          placeholder="Insira o seu número de telefone."
          onChange={(_, d) => setPhone(d.value)}
        />
        <Form.TextArea
          label="Texto:"
          value={text}
          placeholder="Em que podemos ser úteis?"
          onChange={(_, d) => setText(d.value?.toString() || "")}
        />
        {!sent && (
          <button
            className="g-recaptcha rounded-3xl bg-hex-003C95 text-px-19  w-full font-bold text-white py-px-12 px-px-44 cursor-pointer text-center"
            data-sitekey="6Ld6c3odAAAAANfDXTS-DVTdlRkBKKmQdXKSyn0M"
            data-callback="onSubmit"
            data-action="submit"
            disabled={loading}
            onClick={async () => {
              if (!name || !email || !phone || !text) return;
              console.log("abc");
              setLoading(true);
              await sendEmail({
                nome: name,
                email,
                telefone: phone,
                texto: text,
                flags: "",
              });
              resetFields();
              setLoading(false);
              setSent(true);
            }}
          >
            Enviar
          </button>
        )}
        {sent && (
          <button
            className="rounded-3xl bg-hex-3CB371 text-px-19 w-full font-bold text-white py-px-12 px-px-44 cursor-pointer text-center"
            disabled={sent}
          >
            Obrigado! Iremos entrar em contacto consigo brevemente!{" "}
          </button>
        )}
      </Form>
    </div>
  );
}
