import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Footer } from "./components/Footer";
import "./index.css";
import { init } from "emailjs-com";

init("user_Xodgx6sDhhUru0RqxWdCh");

ReactDOM.render(
  <React.StrictMode>
    <App />
    <Footer />
  </React.StrictMode>,
  document.getElementById("root")
);
