import React from "react";
import { Dropdown, Icon, Image } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import logo from "../images/logo.png";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import "./HeaderComponent.css";

export default function Header() {
  const history = useHistory();
  return (
    <div>
      <div className="mb-px-60 mt-px-10 mobile:hidden">
        <div className="z-0 flex flex-row justify-between items-center">
          <Image
            src={logo}
            className="w-px-300 h-px-110 cursor-pointer"
            onClick={() => history.push("/")}
          />
          <Link to="/empreendimentos" className="text-black">
            Empreendimentos
          </Link>
          <Link to="/piscinas" className="text-black">
            Piscinas
          </Link>
          <Link to="/coberturas" className="text-black">
            Coberturas
          </Link>
          <Link to="/revestimentos" className="text-black">
            Revestimentos
          </Link>
          <Link to={"/contacto"}>
            <div className="rounded-3xl bg-hex-003C95 text-px-19 font-bold text-white py-px-12 px-px-44">
              Contacto
            </div>
          </Link>
        </div>
      </div>
      <div className="hidden mobile:sticky mobile:grid mobile:grid-cols-6 mobile:mb-px-30 mt-px-10 z-50">
        <Image
          src={logo}
          className="col-span-4 cursor-pointer"
          onClick={() => history.push("/")}
        />
        <span className="col-span-1 " />
        <Dropdown
          icon={
            <Icon
              name="bars"
              size="big"
              className="bg-hex-003C95 pt-px-15 rounded-full text-white relative"
            />
          }
          basic
          direction="left"
          className="z-50 justify-self-end self-center"
        >
          <Dropdown.Menu className="z-50 relative">
            <Dropdown.Item
              className="text-black"
              text="Empreendimentos"
              onClick={() => history.push("/empreendimentos")}
            />
            <Dropdown.Item
              className="text-black"
              text="Piscinas"
              onClick={() => history.push("/piscinas")}
            />
            <Dropdown.Item
              className="text-black"
              text="Coberturas"
              onClick={() => history.push("/coberturas")}
            />
            <Dropdown.Item
              className="text-black"
              text="Revestimentos"
              onClick={() => history.push("/revestimentos")}
            />
            <Dropdown.Item
              className="text-black"
              text="Contacto"
              onClick={() => history.push("/contacto")}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}
