import React from "react";
import { TitleDescriptionAndCarousel } from "../TitleDescriptionAndCarousel";

import piscinasPrivadas1 from "../../images/piscinas/privadas/piscina1.jpg";
import piscinasPrivadas2 from "../../images/piscinas/privadas/piscina2.jpg";
import piscinasPrivadas3 from "../../images/piscinas/privadas/piscina3.jpg";

import piscinasPublicas1 from "../../images/piscinas/publicas/piscina1.jpg";
import piscinasPublicas2 from "../../images/piscinas/publicas/piscina2.jpg";
import piscinasPublicas3 from "../../images/piscinas/publicas/piscina3.jpg";

export function Piscinas() {
  return (
    <div className="flex flex-row flex-wrap mobile:flex mobile:flex-col items-center justify-between text-center">
      <div className="mb-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Piscinas Privadas"
          body="As piscinas Facomac II, são construídas em qualquer formato e dimensão, sendo o seu prazo médio de construção bastante curto. Concebidas para durar e de fácil manutenção, assentam a base do seu êxito numa estrutura de betão armado de alta resistência."
          images={[piscinasPrivadas1, piscinasPrivadas2, piscinasPrivadas3]}
          bodyHeightClassName="h-px-94"
        />
      </div>
      <div className="mb-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Piscinas Públicas"
          body="A Facomac II, tem neste momento várias piscinas semi-olímpicas e olímpicas em funcionamento, bem como várias escolas de natação, hotéis e condomínios"
          images={[piscinasPublicas1, piscinasPublicas2, piscinasPublicas3]}
          bodyHeightClassName="h-px-94"
        />
      </div>
    </div>
  );
}
