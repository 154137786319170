import React from "react";
import { TitleDescriptionAndCarousel } from "../TitleDescriptionAndCarousel";

import mosaico1 from "../../images/revestimentos/mosaico/foto1.jpg";
import mosaico2 from "../../images/revestimentos/mosaico/foto2.jpg";
import mosaico3 from "../../images/revestimentos/mosaico/foto3.jpg";
import mosaico4 from "../../images/revestimentos/mosaico/foto4.jpg";

import telaArmada1 from "../../images/revestimentos/tela_armada/foto1.jpg";
import telaArmada2 from "../../images/revestimentos/tela_armada/foto2.jpg";
import telaArmada3 from "../../images/revestimentos/tela_armada/foto3.jpg";

export function Revestimentos() {
  return (
    <div className="flex flex-row flex-wrap mobile:flex mobile:flex-col items-center justify-between text-center">
      <div className="mb-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Revestimentos em Mosaico"
          body="Revestimento de alta qualidade. A aplicação do mosaico é executada com o máximo de atenção e cuidado ao detalhe para acabamentos sublimes."
          images={[mosaico1, mosaico2, mosaico3, mosaico4]}
        />
      </div>
      <div className="mb-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Revestimento em Tela Armada"
          body="A Facomac II foi a empresa pioneira em Portugal na aplicação deste material. A tela armada é hoje o revestimento mais característico nas piscinas, para além da sua alta durabilidade, a nossa empresa oferece ainda uma grande variedade de cores e formatos de telas, para que os clientes possam ter um variado leque de escolha para a sua piscina de sonho."
          images={[telaArmada1, telaArmada2, telaArmada3]}
        />
      </div>
    </div>
  );
}
