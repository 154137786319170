import React from "react";
import { TitleDescriptionAndCarousel } from "../TitleDescriptionAndCarousel";

import bomsucesso1 from "../../images/empreendimentos/bom_sucesso_resort_golf/foto1.jpg";
import bomsucesso2 from "../../images/empreendimentos/bom_sucesso_resort_golf/foto2.jpg";
import bomsucesso3 from "../../images/empreendimentos/bom_sucesso_resort_golf/foto3.jpg";
import bomsucesso4 from "../../images/empreendimentos/bom_sucesso_resort_golf/foto4.jpg";
import bomsucesso5 from "../../images/empreendimentos/bom_sucesso_resort_golf/foto5.jpg";
import bomsucesso6 from "../../images/empreendimentos/bom_sucesso_resort_golf/foto6.jpg";
import bomsucesso7 from "../../images/empreendimentos/bom_sucesso_resort_golf/foto7.jpg";

import camporealgolfresort1 from "../../images/empreendimentos/campo_real_golf_resort/foto1.jpg";
import camporealgolfresort2 from "../../images/empreendimentos/campo_real_golf_resort/foto2.jpg";
import camporealgolfresort3 from "../../images/empreendimentos/campo_real_golf_resort/foto3.jpg";
import camporealgolfresort4 from "../../images/empreendimentos/campo_real_golf_resort/foto4.jpg";
import camporealgolfresort5 from "../../images/empreendimentos/campo_real_golf_resort/foto5.jpg";
import camporealgolfresort6 from "../../images/empreendimentos/campo_real_golf_resort/foto6.jpg";

import palmelavillage1 from "../../images/empreendimentos/palmela_village/foto1.jpg";
import palmelavillage2 from "../../images/empreendimentos/palmela_village/foto2.jpg";
import palmelavillage3 from "../../images/empreendimentos/palmela_village/foto3.jpg";
import palmelavillage4 from "../../images/empreendimentos/palmela_village/foto4.jpg";
import palmelavillage5 from "../../images/empreendimentos/palmela_village/foto5.jpg";

import quintadeobidos1 from "../../images/empreendimentos/quinta_de_obidos/foto1.jpg";
import quintadeobidos2 from "../../images/empreendimentos/quinta_de_obidos/foto2.jpg";
import quintadeobidos3 from "../../images/empreendimentos/quinta_de_obidos/foto3.jpg";
import quintadeobidos4 from "../../images/empreendimentos/quinta_de_obidos/foto4.jpg";

export function Empreendimentos() {
  return (
    <div className="flex flex-row flex-wrap mobile:flex mobile:flex-col items-center justify-between text-center">
      <div className="mb-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Bom Sucesso Resort & Golf"
          body="O Bom Sucesso Resort & Golf, situado em Óbidos conta na sua maioria com piscinas Facomac II, neste empreendimento procedemos à construção de piscinas de condomínio e individuais, cada uma com características diferentes indo de encontro ao pedido dos clientes e arquitetos presentes neste projeto, como é o caso dos arquitetos Eduardo Souto Moura e Álvaro Siza Vieira."
          images={[
            bomsucesso1,
            bomsucesso2,
            bomsucesso3,
            bomsucesso4,
            bomsucesso5,
            bomsucesso6,
            bomsucesso7,
          ]}
        />
      </div>
      <div className="mb-px-20 mobile:mt-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Campo Real Golf Resort"
          body="Situado em Torres Vedras, a Facomac II realizou a construção de duas piscinas públicas neste espaço. Uma piscina para crianças e uma piscina de grandes dimensões, assim como duas dezenas de piscinas particulares neste mesmo empreendimento."
          images={[
            camporealgolfresort1,
            camporealgolfresort2,
            camporealgolfresort3,
            camporealgolfresort4,
            camporealgolfresort5,
            camporealgolfresort6,
          ]}
        />
      </div>
      <div className="mb-px-20 mobile:mt-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Palmela Village Golf Resort"
          body="A Facomac II foi o fornecedor do maior empreendimento com piscinas da Europa, tendo construído 550 piscinas no espaço de quatro anos. O projeto deste empreendimento foi da autoria do Arquiteto Tomás Taveira com promoção a cargo da Pelicano."
          images={[
            palmelavillage1,
            palmelavillage2,
            palmelavillage3,
            palmelavillage4,
            palmelavillage5,
          ]}
        />
      </div>
      <div className="mb-px-20 mobile:mt-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Quinta de Óbidos"
          body="As piscinas deste luxuoso espaço foram da autoria da Facomac II, tanto a piscina principal do Country Club, como todas as restantes piscinas das moradias executadas, piscinas estas que contaram com acabamentos em mosaico vítreo e coberturas de estores, algumas com queda de água para ilusão de profundidade e aquecimento."
          images={[
            quintadeobidos1,
            quintadeobidos2,
            quintadeobidos3,
            quintadeobidos4,
          ]}
        />
      </div>
    </div>
  );
}
