import React from "react";
import { TitleDescriptionAndCarousel } from "../TitleDescriptionAndCarousel";

import estore1 from "../../images/coberturas/estore/foto1.jpg";
import estore2 from "../../images/coberturas/estore/foto2.jpg";

import telescopica1 from "../../images/coberturas/telescopica/foto1.jpg";

import verao1 from "../../images/coberturas/verao/foto1.jpg";

export function Coberturas() {
  return (
    <div className="flex flex-row flex-wrap mobile:flex mobile:flex-col items-center justify-between text-center">
      <div className="mb-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Coberturas de Estores"
          body="A cobertura de estores é automática e tem como função principal manter a piscina limpa e protegida do exterior. Pode também desempenhar a função de cobertura isotérmica, bastando para isso que se escolha lâminas apropriadas para o efeito. Esta cobertura pode também ser adaptada a uma piscina já construída. É também considerada uma cobertura de segurança.          "
          images={[estore1, estore2]}
        />
      </div>
      <div className="mb-px-20 mobile:mt-px-20 max-w-px-500 mobile:max-w-full">
        <TitleDescriptionAndCarousel
          title="Coberturas Telescópicas"
          body="A cobertura telescópica é feita em alumínio com o tecto em policarbonato alveolar de 8 mm de espessura tratado contra os raios ultravioletas. Os painéis laterais são transparentes em acrílico de 4 mm de espessura. A sua utilização tem como finalidade o aquecimento da água da piscina através do efeito estufa. Finalmente, mas muito importante, é um sistema bastante seguro para precaver acidentes com crianças."
          images={[telescopica1]}
        />
      </div>
      <div className="mb-px-20 mobile:mt-px-20 max-w-px-500 mobile:max-w-full m-auto">
        <TitleDescriptionAndCarousel
          title="Coberturas de Verão"
          body="Esta cobertura tem a função de não deixar arrefecer a água da piscina durante a noite e aquecê-la durante o dia. Em piscinas aquecidas evita grandes perdas de calor e evaporação."
          images={[verao1]}
          bodyHeightClassName="h-px-76"
        />
      </div>
    </div>
  );
}
