import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Homepage } from "./components/pages/Homepage";
import Header from "./components/HeaderComponent";

import { Piscinas } from "./components/pages/Piscinas";
import { Empreendimentos } from "./components/pages/Empreendimentos";
import { Coberturas } from "./components/pages/Coberturas";
import { Revestimentos } from "./components/pages/Revestimentos";
import { Contactos } from "./components/pages/Contactos";

function App() {
  return (
    <div className="app">
      <div className="flex flex-col m-auto pt-px-10 mobile:p-px-0 mobile:mx-percent-10 max-w-px-1024">
        <Router>
          <Header />
          <Switch>
            <Route path="/piscinas">
              <Piscinas />
            </Route>
            <Route path="/empreendimentos">
              <Empreendimentos />
            </Route>
            <Route path="/coberturas">
              <Coberturas />
            </Route>
            <Route path="/revestimentos">
              <Revestimentos />
            </Route>
            <Route path="/contacto">
              <Contactos />
            </Route>
            <Route>
              <Homepage />
            </Route>
          </Switch>
        </Router>
      </div>
      <div className="hidden mobile:block mobile:h-px-94"></div>
    </div>
  );
}

export default App;
